<template>
  <div
    class="container"
    style="position:fixed;background-color:white;display:flex;width:100%; box-shadow: 0px 3px 10px #00000014;"
    v-if="!isLoading"
  >
    <div class="navbar-brand">
      <a class="navbar-item">
        <img src="../assets/logo.png" />
      </a>
      <a
        role="button"
        @click="changeMenu()"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div v-if="this.menu" class="navbar-menu is-active">
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">{{ this.userData.name }}</a>
            <div class="navbar-dropdown is-right">
              <div class="navbar-item custom">{{ this.userData.name }}</div>
              <div class="navbar-item">{{ this.userData.email }}</div>
              <hr class="navbar-divider" />
              <a class="navbar-item" @click="logout()">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Navbar',
  props: ['permissions'],
  data() {
    return {
      isLoading: true,
      menu: true,
      userData: '',
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.$store.dispatch('checkAuth')
      this.userData = this.$store.getters.currentUser
      this.isLoading = false
    },
    changeMenu() {
      this.menu = !this.menu
    },
    logout: function() {
      this.$buefy.toast.open({ message: 'Logout', type: 'is-success' })
      this.$store
        .dispatch('logout')
        .then(() => this.$router.push({ name: 'login' }))
    },
  },
}
</script>
<style lang="scss">
.navbar {
  height: 56px !important;
  padding-top: 0 !important;
}
.navbar-link:not(.is-arrowless)::after {
  border-color: #193074 !important;
}
.navbar-item img {
  width: 150px;
  max-height: 4rem;
}
@media screen and (min-width: 1024px) {
  .navbar.is-spaced {
    padding-bottom: 0rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .navbar.is-spaced {
    padding-right: 0.5% !important;
  }
}
</style>
