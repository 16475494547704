import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import ApiService from './common/api.service'
import CKEditor from 'ckeditor4-vue'
import VueClipboard from 'vue-clipboard2'

import { ValidationProvider, extend } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faEnvelope,
  faLock,
  faPlus,
  faCalendar,
  faSave,
  faEye,
  faEyeSlash,
  faArrowUp,
  faAngleLeft,
  faAngleRight,
  faTimesCircle,
  faUserEdit,
  faFileImport,
  faFileExport,
  faSearch,
  faUpload,
  faArrowRight,
  faTimes,
  faTrash,
  faInfo,
  faPoll,
  faForward,
  faBackward,
  faExclamationCircle,
  faCheck,
  faFileExcel,
  faFrown,
  faPen,
  faCopy,
  faArrowsAlt,
  faCaretDown,
  faCaretUp,
  faFilePdf,
  faPencilRuler,
  faMinus,
  faFlag,
  faEdit,
  faArrowAltCircleRight,
  faClipboard,
  faArrowLeft,
  faTag,
  faSpinner,
  faGripHorizontal,
  faEllipsisV,
  faChartBar,
  faDiceD6,
  faPuzzlePiece,
  faCircleNotch,
  faPlay,
  faUserFriends,
  faUsers,
  faList,
  faIdBadge,
  faCog,
  faPencilAlt,
  faLink,
  faPlusCircle,
  faArchive,
  faUndoAlt,
  faShare,
  faSort,
  faStar,
  faClipboardCheck,
  faTasks,
  faPaperPlane,
  faCheckCircle,
  faBookmark,
  faListAlt,
  faLayerGroup,
  faThLarge,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'

import {
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faEnvelope,
  faLock,
  faPlus,
  faCalendar,
  faSave,
  faEye,
  faEyeSlash,
  faArrowUp,
  faAngleLeft,
  faAngleRight,
  faTimesCircle,
  faUserEdit,
  faFileImport,
  faFileExport,
  faSearch,
  faUpload,
  faArrowRight,
  faTimes,
  faTrash,
  faInfo,
  faPoll,
  faForward,
  faBackward,
  faExclamationCircle,
  faCheck,
  faFileExcel,
  faFrown,
  faPen,
  faCopy,
  faArrowsAlt,
  faCaretDown,
  faCaretUp,
  faFilePdf,
  faPencilRuler,
  faMinus,
  faFlag,
  faCaretDown,
  faCaretUp,
  faEdit,
  faArrowAltCircleRight,
  faClipboard,
  faArrowLeft,
  faTag,
  faSpinner,
  faGripHorizontal,
  faEllipsisV,
  faChartBar,
  faClipboard,
  faDiceD6,
  faPuzzlePiece,
  faCircleNotch,
  faPlay,
  faUserFriends,
  faUsers,
  faList,
  faIdBadge,
  faCog,
  faPencilAlt,
  faEye,
  faLink,
  faCopy,
  faPlusCircle,
  faPencilAlt,
  faArchive,
  faUndoAlt,
  faShare,
  faSort,
  faStar,
  faClipboardCheck,
  faTasks,
  faPaperPlane,
  faCheckCircle,
  faPlusSquare,
  faMinusSquare,
  faBookmark,
  faListAlt,
  faLayerGroup,
  faThLarge,
  faDownload
)

Vue.component('vue-fontawesome', FontAwesomeIcon)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})

Vue.use(CKEditor)
Vue.use(VueClipboard)

extend('email', {
  ...email,
  message: 'Pada bagian ini jawaban harus berupa email',
})

extend('password', {
  validate: (value, { other }) => value === other,
  message: 'Pada bagian ini password konfirmasi harus Sama.',
  params: [{ name: 'other', isTarget: true }],
})

extend('minsecond', {
  params: ['min'],
  validate(value, { min }) {
    return value >= min
  },
  message: 'Pada bagian ini harus minimal {min} detik',
})

extend('min', {
  params: ['min'],
  validate(value, { min }) {
    return value > min
  },
  message: 'Pada bagian ini harus minimal {min}',
})

extend('max', {
  params: ['max'],
  validate(value, { max }) {
    return value < max
  },
  message: 'Pada bagian ini harus maximum {max}',
})

extend('requiredarray', {
  ...required,
  validate(value) {
    return value.length >= 1
  },
  message: 'Bagian ini wajib diisi',
})

extend('requiredarraybetween', {
  params: ['min', 'max'],
  validate(value, { min, max }) {
    var result = value.length >= min && value.length <= max
    return result
  },
  message: 'Pada bagian ini harus diisi antara {min} dan {max} jawaban',
})

extend('betweenchar', {
  params: ['rules'],
  validate(value, { rules }) {
    return value.length >= rules.min && value.length <= rules.max
  },
  message:
    'Pada bagian ini harus mengisikan minimum dan maksimum karakter-karakter yang akan di input',
})

extend('minchar', {
  params: ['min'],
  validate(value, { min }) {
    return value.length >= min
  },
  message: 'Pada bagian ini harus minimal {min} karakter',
})

extend('maxchar', {
  params: ['max'],
  validate(value, { max }) {
    return value.length <= max
  },
  message: 'Pada bagian ini harus maximum {max} karakter',
})

extend('betweenword', {
  params: ['rules'],
  validate(value, { rules }) {
    let arrayWord = value.replace(/\s+/g, ' ').split(' ')
    return (
      arrayWord.length >= rules.min &&
      arrayWord.length <= rules.max &&
      arrayWord[rules.min - 1] != ''
    )
  },
  message:
    'Pada bagian ini harus mengisikan minimum dan maksimum kata-kata yang akan di input',
})

extend('minword', {
  params: ['minword'],
  validate(value, { minword }) {
    let arrayWord = value.replace(/\s+/g, ' ').split(' ')
    return arrayWord.length >= minword && arrayWord[minword - 1] != ''
  },
  message: 'Pada bagian ini harus maksimum {minword} kata',
})

extend('maxword', {
  params: ['maxword'],
  validate(value, { maxword }) {
    let arrayWord = value.replace(/\s+/g, ' ').split(' ')
    return arrayWord.length <= maxword
  },
  message: 'Pada bagian ini harus maksimum {maxword} kata',
})

extend('required', {
  ...required,
  message: 'Bagian ini wajib diisi',
})

extend('checkboxmax', {
  params: ['max'],
  validate(value, { max }) {
    return value.length <= max
  },
  message: 'Wajib memilih maksimum {max} pilihan jawaban',
})

extend('checkboxmin', {
  params: ['min'],
  validate(value, { min }) {
    return value.length >= min
  },
  message: 'Wajib memilih minimal {min} pilihan jawaban',
})

ApiService.init()
new Vue({
  router,
  store,
  Buefy,
  render: h => h(App),
}).$mount('#app')

window.$ = window.jQuery = require('jquery')
