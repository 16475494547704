import ApiService from '@/common/api.service'

const newAnswer = {
  namespaced: true,
  state: () => ({
    errors: null,
    newAnswers: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new answers
    getNewAnswers(state) {
      return state.newAnswers
    },
  },
  actions: {
    // For new answers
    fetchAnswers(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/candidat/${content.nit}/show`, {
          code: content.code,
          name: content.name,
          appUrl: content.appUrl,
          email: content.email,
          cid: content.cid,
          uid: content.uid,
          tesId: content.tesId,
          preview: content.preview,
        }).then(
          response => {
            if (response.status === 200) {
              resolve(response)
              return response
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update answer
    updateAnswer(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/${form.nit}/update`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update answer connection failed
    updateAnswerConnectionFailed(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form[0].nit}/update/connectionfailed`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store open tab
    storeOpenNewTab(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/store-open-tab`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update active step
    updateActiveStep(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/update-active-step`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update time
    updateTime(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/update-time`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden photo
    candidatPhoto(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/candidat-photo`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden photo
    storeHiddenPhoto(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/store-hidden-photo`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden screen capture
    storeHiddenScreenCapture(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/store-hidden-screen-capture`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store cideo played
    storeVideoPlayed(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/store-video-played`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store proctoring record
    async storeProctoringRecord(context, content) {
      ApiService.setClientHeader()
      ApiService.setHeaderMultipartFormData()

      const response = await ApiService.post(
        `api/v2/candidat/${content.nit}/store-proctoring-record`,
        content,
        true
      )
      return response.data
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new answers
    setNewAnswers(state, data) {
      state.newAnswers = data
    },
  },
}

export default newAnswer
