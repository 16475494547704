import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth.module'
import user from './user.module'
import branch from './branch.module'
import assessment from './assessment.module'
import report from './report.module'
import category from './category.module'
import psikotestIntroduction from './psikotest-introduction.module'
import caseStudy from './case-study.module'
import interview from './interview.module'
import newAssessment from './V2/newAssessment.module'
import newSection from './V2/newSection.module'
import newCategory from './V2/newCategory.module'
import newTag from './V2/newTag.module'
import newLevel from './V2/newLevel.module'
import questionStorage from './V2/questionStorage.module'
import newAnswer from './V2/newAnswer.module'
import newReport from './V2/newReport.module'

import answer from './answer.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    branch,
    assessment,
    report,
    category,
    psikotestIntroduction,
    answer,
    caseStudy,
    interview,
    newAssessment,
    newSection,
    newCategory,
    newTag,
    newLevel,
    questionStorage,
    newAnswer,
    newReport,
  },
})
