import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import router from "@/router";

const state = {
  errors: null,
  message: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  async login(context, credentials) {
    const response = await ApiService.post("oauth/token", {
      username: credentials.username,
      password: credentials.password,
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_SECRET
    });
    if (response.status == 200) {
      context.commit("setAccessToken", response.data);
      var lastDate = new Date(new Date().setDate(new Date().getDate() - 30));
      var firstDate = new Date();
      context.dispatch("checkAuth").then(() => {
        router.push({
          name: "assessment",
          params: { lastDay: firstDate, firstDay: lastDate }
        });
      });
    }
  },
  async forgot(context, credentials) {
    const response = await ApiService.get("api/forgot", {
      email: credentials.username,
      token: credentials.token
    });
    if (response.status == 200) {
      router.push({ name: "login" });
    }
  },
  async resetPassword(context, credentials) {
    const response = await ApiService.post("api/reset", {
      token: credentials.token,
      password: credentials.password
    });
    if (response.status == 200) {
      router.push({ name: "login" });
    }
  },
  logout(context) {
    context.commit("purgeAuth");
  },
  async register(context, credentials) {
    ApiService.setHeader();
    return ApiService.post("api/user/store", {
      name: credentials.name,
      email: credentials.email,
      password: credentials.password,
      password_confirmation: credentials.passwordConfirmation
    });
  },
  async checkAuth(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/user/checkAuth").catch(
        response => {
          context.commit("setError", response);
          context.commit("purgeAuth");
        }
      );
      if (response.status == 200) {
        context.commit("setAuthenticated");
        context.commit("setAuth", response.data);
        return true;
      }
    } else {
      context.commit("purgeAuth");
      return this.getters.isAuthenticated;
    }
  }
};
const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setAuthenticated() {
    state.isAuthenticated = true;
  },
  setAuth(state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
  },
  setAccessToken(state, data) {
    JwtService.saveToken(data.accessToken);
  },
  purgeAuth(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
