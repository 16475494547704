import ApiService from '@/common/api.service'

const newSection = {
  namespaced: true,
  state: () => ({
    errors: null,
    newSections: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new sections
    getNewSections(state) {
      return state.newSections
    },
  },
  actions: {
    // For new section by new assessment
    fetchSectionsByAssessment(context, assessmentId) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/section/${assessmentId}`, {}).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewSections', response.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchSection(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.get(`/api/v2/section/${id}/show`, {}).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For create section
    createSection(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/section/${form.assessmentId}`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update section
    updateSection(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.put(`/api/v2/section/${form.id}/update`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For duplicate section
    duplicateSection(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/section/${id}/duplicate`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For delete section
    deleteSection(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/section/${id}/delete`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For preview section
    previewSection(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.get(`/api/v2/section/preview`, content).then(
          response => {
            resolve(response)
            return response
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new sections
    setNewSections(state, data) {
      state.newSections = data
    },
  },
}

export default newSection
