<template>
  <b-sidebar
    type="is-light"
    position="static"
    :fullwidth="true"
    :fullheight="this.fullheight"
    :reduce="reduce"
    open
  >
    <div
      :class="$route.name == 'reportAssessment' ? 'p-1' : 'p-2'"
      style="position:fixed;width:320px;"
    >
      <b-menu class="is-custom-mobile">
        <b-menu-list>
          <!-- For assessment v2 (with bank soal) -->
          <b-menu-item
            :active="isActiveAssessment"
            v-bind:class="{ caret: this.statusAssessment == false }"
            :expanded="isExpandedAssessment"
          >
            <template slot="label">
              <span class="menu-position menu-text">
                <b-icon
                  pack="fas"
                  icon="chart-bar"
                  class="menu-position-left"
                ></b-icon>
                <span class="menu-position-center">
                  Assessment with
                  <br />
                  Bank Soal
                </span>
              </span>
            </template>
            <b-menu-item tag="router-link" :to="{ name: `assessment` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="clipboard"></b-icon>
                  <span class="menu-position-inside">Assessment Setup</span>
                </span>
              </template>
            </b-menu-item>
            <b-menu-item
              tag="router-link"
              :to="{ name: `reportAssessment` }"
            >
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="exclamation-circle"></b-icon>
                  <span class="menu-position-inside">Assessment Report</span>
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>

          <!-- For case study -->
          <b-menu-item
            :active="isActiveCaseStudy"
            v-bind:class="{ caret: this.statusCaseStudy == false }"
            :expanded="isExpandedCaseStudy"
          >
            <template slot="label">
              <span class="menu-position menu-text">
                <b-icon
                  pack="fas"
                  icon="dice-d6"
                  class="menu-position-left"
                ></b-icon>
                <span class="menu-position-center">Case Study</span>
              </span>
            </template>
            <b-menu-item tag="router-link" :to="{ name: `caseStudy` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="puzzle-piece"></b-icon>
                  <span class="menu-position-inside">Case Study Setup</span>
                </span>
              </template>
            </b-menu-item>
            <b-menu-item tag="router-link" :to="{ name: `reportCaseStudy` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="exclamation-circle"></b-icon>
                  <span class="menu-position-inside">Case Study Report</span>
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>

          <!-- For detail bank soal -->
          <b-menu-item
            :active="isActiveBankSoal"
            v-bind:class="{ caret: this.statusBankSoal == false }"
            :expanded="isExpandedBankSoal"
          >
            <template slot="label">
              <span class="menu-position menu-text">
                <b-icon
                  pack="fas"
                  icon="bookmark"
                  class="menu-position-left"
                ></b-icon>
                <span class="menu-position-center">Bank Soal</span>
              </span>
            </template>

            <!-- For bank soal -->
            <b-menu-item tag="router-link" :to="{ name: `bankSoal` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="bookmark"></b-icon>
                  <span class="menu-position-inside">Bank Soal Setup</span>
                </span>
              </template>
            </b-menu-item>

            <!-- For category -->
            <b-menu-item tag="router-link" :to="{ name: `newCategories` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="th-large"></b-icon>
                  <span class="menu-position-inside">Category Bank Soal</span>
                </span>
              </template>
            </b-menu-item>

            <!-- For level -->
            <b-menu-item tag="router-link" :to="{ name: `newLevels` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="layer-group"></b-icon>
                  <span class="menu-position-inside">Customize Level</span>
                </span>
              </template>
            </b-menu-item>

            <!-- For tag -->
            <b-menu-item tag="router-link" :to="{ name: `newTags` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="tag"></b-icon>
                  <span class="menu-position-inside">Tag</span>
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>
          <!-- <b-menu-item
            :active="isActiveLgd"
            v-bind:class="{ caret: this.statusLgd == false }"
            :expanded="isExpandedLgd"
          >
            <template slot="label">
              <span class="menu-position menu-text">
                <b-icon
                  pack="fas"
                  icon="circle-notch"
                  class="menu-position-left"
                ></b-icon>
                <span class="menu-position-center"> LGD </span>
              </span>
            </template>
            <b-menu-item tag="router-link" :to="{ name: `interview` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="user-friends"> </b-icon>
                  <span class="menu-position-inside">LGD Setup</span></span
                >
              </template>
            </b-menu-item>
            <b-menu-item tag="router-link" :to="{ name: `interviewVideo` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="play"> </b-icon>
                  <span class="menu-position-inside">LGD Video</span></span
                >
              </template>
            </b-menu-item>
          </b-menu-item> -->
          <b-menu-item
            :active="isActiveSettingsData"
            v-bind:class="{ caret: this.statusSettingsData == false }"
            :expanded="isExpandedSettings"
          >
            <template slot="label">
              <span class="menu-position menu-text">
                <b-icon
                  pack="fas"
                  icon="cog"
                  class="menu-position-left"
                ></b-icon>
                <span class="menu-position-center">Setting</span>
              </span>
            </template>
            <b-menu-item tag="router-link" :to="{ name: `user` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="users"></b-icon>
                  <span class="menu-position-inside">Users</span>
                </span>
              </template>
            </b-menu-item>
            <b-menu-item tag="router-link" :to="{ name: `category` }">
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="list"></b-icon>
                  <span class="menu-position-inside">Category</span>
                </span>
              </template>
            </b-menu-item>
            <b-menu-item
              tag="router-link"
              :to="{ name: `psikotestIntroduction` }"
            >
              <template slot="label">
                <span class="menu-position menu-text">
                  <b-icon pack="fas" icon="id-badge"></b-icon>
                  <span class="menu-position-inside">
                    Psikotest Introduction
                  </span>
                </span>
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
      <!-- <b-menu class="sidebar-btt">
        <b-menu-list>
          <b-menu-item label="Logout" @click="logout()"></b-menu-item>
        </b-menu-list>
      </b-menu>-->
    </div>
  </b-sidebar>
</template>
<script>
export default {
  name: 'Sidebar',
  props: ['permissions'],
  data() {
    return {
      isLoading: true,
      user: [],
      fullheight: true,
      isActiveSettingsData: false,
      isActiveAssessment: false,
      isActiveCaseStudy: false,
      isActiveBankSoal: false,
      iActiveLgd: false,
      statusSettingsData: false,
      statusAssessment: false,
      statusCaseStudy: false,
      statusBankSoal: false,
      statusLgd: false,
      statusSetting: false,
      isExpandedSettings: false,
      isExpandedAssessment: false,
      isExpandedCaseStudy: false,
      isExpandedBankSoal: false,
      isExpandedLgd: false,
      isActiveSetting: false,
      isExpandedSetting: false,
      reduce: false,
    }
  },
  created() {
    this.checkRoute()
  },
  watch: {
    '$route.name': function(to) {
      //for sidebar Settings data

      var isUser = -1
      var isCategory = -1
      var isPsikotestIntroduction = -1
      var isAssessment = -1
      var isAssessmentReport = -1
      var isCaseStudy = -1
      var isCaseStudyReport = -1
      var isLgd = -1
      var isLgdVideo = -1
      var isNewCategory = -1
      var isNewTag = -1
      var isNewLevel = -1
      var isBankSoal = -1

      isUser = to.toLowerCase().search('user')
      isCategory = to.toLowerCase().search('category')
      isPsikotestIntroduction = to.toLowerCase().search('psikotestintroduction')

      if (isUser >= 0 || isCategory >= 0 || isPsikotestIntroduction >= 0) {
        this.isActiveSettingsData = true
        this.statusSettingsData = true
        this.isExpandedSettings = true
      } else {
        this.statusSettingsData = false
        this.isActiveSettingsData = false
      }

      isAssessment = to.toLowerCase().search('assessment')
      isAssessmentReport = to.toLowerCase().search('reportassessment')
      if (isAssessment >= 0 || isAssessmentReport >= 0) {
        this.isActiveAssessment = true
        this.statusAssessment = true
        this.isExpandedAssessment = true
      } else {
        this.isActiveAssessment = false
        this.statusAssessment = false
      }

      isCaseStudy = to.toLowerCase().search('casestudy')
      isCaseStudyReport = to.toLowerCase().search('reportcasestudy')
      if (isCaseStudy >= 0 || isCaseStudyReport >= 0) {
        this.isActiveCaseStudy = true
        this.statusCaseStudy = true
        this.isExpandedCaseStudy = true
      } else {
        this.isActiveCaseStudy = false
        this.statusCaseStudy = false
      }

      isLgd = to.toLowerCase().search('interview')
      isLgdVideo = to.toLowerCase().search('interviewvideo')
      if (isLgd >= 0 || isLgdVideo >= 0) {
        this.isActiveLgd = true
        this.statusLgd = true
        this.isExpandedLgd = true
      } else {
        this.isActiveLgd = false
        this.statusLgd = false
      }

      isNewCategory = to.toLowerCase().search('newcategories')
      isNewTag = to.toLowerCase().search('newtags')
      isNewLevel = to.toLowerCase().search('newlevels')
      isBankSoal = to.toLowerCase().search('banksoal')
      if (
        isNewCategory >= 0 ||
        isNewTag >= 0 ||
        isNewLevel >= 0 ||
        isBankSoal >= 0
      ) {
        this.isActiveBankSoal = true
        this.statusBankSoal = true
        this.isExpandedBankSoal = true
      } else {
        this.isActiveBankSoal = false
        this.statusBankSoal = false
      }
    },
  },
  methods: {
    logout: function() {
      this.$buefy.toast.open({ message: 'Logout', type: 'is-success' })
      this.$store
        .dispatch('logout')
        .then(() => this.$router.push({ name: 'login' }))
    },
    checkRoute() {
      var route = this.$route.name

      var isUser = -1
      var isCategory = -1
      var isPsikotestIntroduction = -1
      var isAssessment = -1
      var isAssessmentReport = -1
      var isCaseStudy = -1
      var isCaseStudyReport = -1
      var isLgd = -1
      var isLgdVideo = -1
      var isNewCategory = -1
      var isNewTag = -1
      var isNewLevel = -1
      var isBankSoal = -1

      isUser = route.toLowerCase().search('user')
      isCategory = route.toLowerCase().search('category')
      isPsikotestIntroduction = route
        .toLowerCase()
        .search('psikotestintroduction')

      if (isUser >= 0 || isCategory >= 0 || isPsikotestIntroduction >= 0) {
        this.isActiveSettingsData = true
        this.statusSettingsData = true
        this.isExpandedSettings = true
      } else {
        this.isActiveSettingsData = false
        this.statusSettingsData = false
      }

      isAssessment = route.toLowerCase().search('assessment')
      isAssessmentReport = route.toLowerCase().search('reportassessment')
      if (isAssessment >= 0 || isAssessmentReport >= 0) {
        this.isActiveAssessment = true
        this.statusAssessment = true
        this.isExpandedAssessment = true
      } else {
        this.isActiveAssessment = false
        this.statusAssessment = false
      }

      isCaseStudy = route.toLowerCase().search('casestudy')
      isCaseStudyReport = route.toLowerCase().search('reportcasestudy')
      if (isCaseStudy >= 0 || isCaseStudyReport >= 0) {
        this.isActiveCaseStudy = true
        this.statusCaseStudy = true
        this.isExpandedCaseStudy = true
      } else {
        this.isActiveCaseStudy = false
        this.statusCaseStudy = false
      }

      isLgd = route.toLowerCase().search('interview')
      isLgdVideo = route.toLowerCase().search('interviewvideo')
      if (isLgd >= 0 || isLgdVideo >= 0) {
        this.isActiveLgd = true
        this.statusLgd = true
        this.isExpandedLgd = true
      } else {
        this.isActiveLgd = false
        this.statusLgd = false
      }

      isNewCategory = route.toLowerCase().search('newcategories')
      isNewTag = route.toLowerCase().search('newtags')
      isNewLevel = route.toLowerCase().search('newlevels')
      isBankSoal = route.toLowerCase().search('banksoal')
      if (
        isNewCategory >= 0 ||
        isNewTag >= 0 ||
        isNewLevel >= 0 ||
        isBankSoal >= 0
      ) {
        this.isActiveBankSoal = true
        this.statusBankSoal = true
        this.isExpandedBankSoal = true
      } else {
        this.isActiveBankSoal = false
        this.statusBankSoal = false
      }
    },
  },
}
</script>
<style lang="scss">
@media (min-width: 1024px) and (max-width: 1200px) {
  .p-1 {
    padding-right: 2em !important;
  }
  .p-2 {
    padding-right: 1em !important;
  }
}
@media screen and (min-width: 1216px) {
  .p-1 {
    padding: 1em !important;
  }
  .p-2 {
    padding: 1em !important;
  }
  .menu-position-center {
    font-size: 16px;
  }
  .menu-position-left {
    font-size: 16px;
  }
}
.logo {
  margin-left: 10%;
}
.b-sidebar .sidebar-content {
  box-shadow: none !important;
}
.sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 16rem;
  padding: 3rem 1rem;
  background: white;
}
.menu-list a.is-active {
  background-color: #00b79f !important;
  color: #ffffff;
}

.menu-list a:hover {
  background-color: #cefff9 !important;
  color: #7e7e7e;
}

.menu-list a {
  color: #7e7e7e;
}

.menu-list .caret a.is-active {
  color: #4a4a4a !important;
  background-color: white !important;
}
.sidebar-content {
  padding: 0.75rem 0 0.75rem 0.75rem;
  background-color: white;
}
.label {
  font-size: 14px !important;
}
.p-1 {
  padding: 1em 0 1em 0;
}
.menu-list li ul {
  margin: 0.75em 0 0.75em 0.75em;
}
.menu-text {
  width: 100%;
}
.menu-position {
  display: inline-flex;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);

  // &-left {
  //   // padding-right: 10%;
  //   background-color: white;
  //   color:#00a3a3;
  //   padding: 5%;
  // }

  // &-icon-right {
  //   padding-left: 10%;
  // }
  &-inside {
    padding-left: 5%;
  }
  &-center {
    padding-left: 5%;
    padding-right: 10%;
  }
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
