<template>
  <main>
    <nav class="navbar docs-navbar" style="background:transparent;">
      <Navbar :permissions="this.permissions" />
    </nav>
    <section class="documentation">
      <div class="sidebar-bg"></div>
      <div class="container is-fullhd docs-template-horizontal">
        <div class="columns body-content">
          <div class="column is-one-fifth sidebar-content">
            <Sidebar :permissions="this.permissions" />
          </div>
          <div class="column content-view is-mobile">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapGetters } from 'vuex'
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'Home',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      menu: true,
      isLoading: true,
      permissions: [],
      user: '',
      burger: false,
    }
  },
  mounted() {
    if (this.$route.name == 'home') {
      this.lastDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.firstDate = new Date()
      this.$router.push({
        name: 'assessment',
        params: { lastDay: this.firstDate, firstDay: this.lastDate },
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.$store.dispatch('checkAuth')
      this.user = this.$store.getters.currentUser
      this.permissions = this.$store.getters.currentUser.permissions
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss">
.icon-user-name {
  padding-right: 10%;
}
.navbar .has-shadow {
  background-color: transparent;
}
.b-sidebar .sidebar-content.is-fullheight {
  background-color: white;
}
.content-view {
  background-color: whitesmoke;
}
.sidebar-content {
  background-color: white;
  width: 340px !important;
}
.help {
  display: none !important;
}
.taginput .taginput-container > .tag,
.taginput .taginput-container > .tags {
  height: 2.5em !important;
}

.documentation {
  max-height: 100vh;
  height: 100vh;
}

.breadcrumb li {
  color: #c1c1c1;
}

.breadcrumb li + li::before {
  padding-left: 9%;
  content: '>';
}
.breadcrumb li:last-child {
  color: #024886;
  font-weight: bold;
}

.index {
  height: 100vh;
}

.separator-line {
  border-bottom: 1px solid hsla(0, 0%, 86%, 0.5);
}

.content {
  &-title {
    padding-bottom: 0;

    &__text {
      font-size: 24px;
      font-weight: 600;
      color: #4a4a4a;
      margin-bottom: 16px;
    }
  }
}
// .body-content {
//   height: 100vh;
//   max-height: 100vh;
// }

// .content-view {
//   overflow-y: auto;
//   overflow-x: hidden;
// }
</style>
